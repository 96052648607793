import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import BlogPostList from '../components/BlogPostList/BlogPostList';

const propTypes = {
  data: PropTypes.object.isRequired,
};

class BlogPostListTemplate extends React.Component {

  render() {
    const { data, location, pageContext } = this.props;
    return (
      <Layout
        type='standard'
        location={location}
      >
        <SEO
          title={data.blogIndexYaml.title}
          meta={data.blogIndexYaml.metaTags}
          canonical={pageContext.canonicalURL}
        />
        <BlogPostList
          data={data.blogIndexYaml.listingBlock}
          posts={data.blogs.edges}
          pageContext={pageContext}
        />
      </Layout>
    );
  }
}

BlogPostListTemplate.propTypes = propTypes;

export default BlogPostListTemplate;


export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    blogIndexYaml {
      title
      metaTags {
        name
        content
      }
      listingBlock {
        title
      }
    }
    blogs: allFile(
      filter: {sourceInstanceName: {eq: "blog"}}
      sort: {fields: childMdx___frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          childMdx {
            id
            frontmatter {
              path
              description
              date(formatString: "MMMM Do, YYYY")
              title
              image: previewImage {
                childImageSharp {
                  fluid(maxWidth: 535, maxHeight: 334, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              description
            }
          }
        }
      }
    }
  }
`