import React from 'react';
import Img from 'gatsby-image';
import StandardLink, { ArrowLink } from 'components/Shared/Link/Link';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './BlogPostList.scss';

const BlogPostPreview = ({ post }) => (
  <div className="aaa-blogpost-preview">
    <div className="row">
      <div className="col-12">
        <h3 className="aaa-blogpost-preview__previewTitle">
          <StandardLink to={post.path}>{post.title}</StandardLink>
        </h3>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-lg-5">
        <StandardLink to={post.path}>
          <Img
            className="aaa-blogpost-preview__img"
            alt=""
            fluid={post.image.childImageSharp.fluid}
          />
        </StandardLink>
      </div>
      <div className="col-xs-12 col-lg-7 d-flex">
        <div className="aaa-blogpost-preview__content-box">
          <div className="aaa-blogpost-preview__content">
            <small className="aaa-blogpost-preview__date">{post.date}</small>
            <div className="aaa-blogpost-preview__description">
              {post.description}
            </div>
          </div>
          <div className="aaa-blogpost-preview__more">
            <ArrowLink className="" to={post.path}>
              Read more
            </ArrowLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class Pagination extends React.Component {
  constructor() {
    super();
  }
  render() {
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage - 1 === 1
        ? '/blog'
        : `/blog/page-${(currentPage - 1).toString()}`;
    const nextPage = `/blog/page-${(currentPage + 1).toString()}`;

    return (
      <div className="aaa-blog__pagination">
        <ul className="aaa-blog__pagination-list">
          {!isFirst && (
            <li>
              <StandardLink to={prevPage} rel="prev">
                ← Previous Page
              </StandardLink>
            </li>
          )}
          {!isLast && (
            <li>
              <StandardLink to={nextPage} rel="next">
                Next Page →
              </StandardLink>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const BlogPostList = ({ data, posts, pageContext }) => (
  <div className="aaa-blog">
    <ContentBox>
      <h1 className="aaa-blog__title">{data.title}</h1>
      <div className="aaa-blog__list-container">
        {posts.map(({ node }) => {
          return (
            <div key={node.childMdx.frontmatter.path}>
              <BlogPostPreview post={node.childMdx.frontmatter} />
            </div>
          );
        })}
      </div>
      <Pagination pageContext={pageContext} />
    </ContentBox>
  </div>
);
export default BlogPostList;
